#root {
  white-space: pre-line;
}

.sample-trigger {
  display: none;
}

.shadow-small {
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
}

.shadow-none {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.bg-pattern-1 {
  background: url(../img/background1.svg) repeat-y center / cover;
}

.bg-pattern-2 {
  background: url(../img/background2.svg) repeat-y center / cover;
}

.bg-pattern {
  background: url(../img/background.svg) repeat-y center / cover;
}

.bg-blured-back {
  backdrop-filter: blur(40px) brightness(100%);
  background: rgba(255, 255, 255, 0.65);
}

.track {
  height: 5px;
  border-radius: 10px;

  &.track-0 {
    background: $secondary;
  }

  &.track-1 {
    background: $primary;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.thumb {
  border-radius: 50%;
  border: 3px solid white;
  background: $secondary;
  padding: 0.4em;
  margin-top: -0.5em;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
}

.css-style {
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: .2em;
  }

  p {
    margin: 0.5em 0 0 0;
  }
}



.bulle-title {
  width: 40px;
  height: 40px;
  background: $secondary;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  font-family: $fontMain;
  font-weight: 800;
  color: white;

}

.text-anatomie {
  padding-right: 8px;
}

.introduction {
  position: relative;

  .bulle {
    z-index: 100;
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background: $secondary;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    font-family: $fontMain;
    font-weight: 800;
    color: white;
    @media screen and (max-width: 510px) {
      width: 14px;
      height: 14px;
      font-size: 10px;

    }

    &.anatomie-monpubis {
      left: 50%;
      transform: translateX(-50%);
      top: 48px;
      @media screen and (max-width: 510px) {
        top: 30px;
      }
    }

    &.anatomie-glansclitoris {
      left: 45%;
      top: 100px;

      @media screen and (max-width: 510px) {
        top: 69px;

      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-duterlabia {
      left: 42.8%;
      top: 160px;
      @media screen and (max-width: 510px) {
        &.no-top {
          top: 88px;
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-innerlabia {
      top: 135px;
      left: 45%;
      @media screen and (max-width: 510px) {
        top: 93px;
        transform: translateX(3px)  ;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-vaginalopening {
      top: 172px;
      left: 43%;
      @media screen and (max-width: 510px) {
        top: 116px;
        transform: translateX(7px);
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-anus {
      bottom: 33px;
      top: inherit;
      left: 45%;

      @media screen and (max-width: 510px) {
        bottom: 21px;

      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-clitoralhood {
      right: 45%;
      left: inherit;
      top: 82px;
      left: inherit;
      transform: translateX(-10px);
      @media screen and (max-width: 510px) {
        top: 60px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        //left: -36px;
        //width: 36px;
        left: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-uretralopening {
      right: 44.8%;
      left: inherit;
      top: 125px;
      left: inherit;
      @media screen and (max-width: 510px) {
        top: 83px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-hymen {
      right: 44.8%;
      left: inherit;
      z-index: 10;
      top: 150px;
      left: inherit;
      z-index: 120;
      @media screen and (max-width: 510px) {
        top: 98px;
        transform: translateX(-4px);
      }
        &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -28px;
        width: 30px;
        height: 2px;
        background: $secondary;
      }
    }

    &.anatomie-vestibule {
      left: inherit;
      right: 41.9%;
      top: 130.9px;
      background: red;

      @media screen and (max-width: 510px) {
        &.no-top {
          top: 87px;
          transform: translateX(-5px);
          &:before {
            width: 49px;
          }
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -40px;
        width: 60px;
        height: 2px;
        background: red;
        z-index: -1;
      }

      .crochet {
        position: absolute;
        left: -40px;
        height: 40px;
        top: -5px;
        width: 2px;
        background: red;
        @media screen and (max-width: 510px) {
          height: 45px;
        }

        &:before, &:after {
          content: "";
          width: 10px;
          height: 2px;
          background: red;
          position: absolute;
          left: -10px;
        }

        &:before {
          top: 0;
        }

        &:after {
          bottom: 0;
        }
      }
    }
  }
}
.f-custom {
  flex: 0 0 64px!important;
  max-width: 64px!important;
}
.cursor-pointer {
  cursor: pointer;
}
.bloc-flex-200 {
  flex: 0 0 200px;
  max-width: 200px;
}

.bloc-left {
  flex: 0 0 230px;
}

.bloc-flex {
  flex: 0 0 475px;
  @media screen and (max-width: 550px) {
    flex: 0 0 100%
  }
}

.modal {
  font-size: 12px;
}

.popup-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  border: 0px solid transparent !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: transparent !important;

  .modal {
    background: #FFFFFF;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  @media screen and (max-width: 800px) {
    width: 100% !important;
    max-width: 400px !important;
    .close {
      right: 0 !important;
    }

  }
}

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 12px 0;
}

.modal > .content {
  width: 100%;
  padding: 10px 5px;
}

.modal > .actions {
  width: 100%;
  padding: 0;
  margin: auto;
  text-align: center;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
.hyphens {
  &.mt-05 {
    padding-left: 58px!important;
    margin-top: 0!important;
  }
}
.introduction {
  .svg-illustration {
    .vestibul {
      max-width: 333px;
      height: 215px;
      transform: scale(1.6);
      @media screen and (max-width: 510px) {
        width: 100%;
        height: auto;
        transform: scale(0.9);
      }
    }
  }
}
