$list-modif-color-light: (
    "l": 5%,
    "ll": 30%,
);
$list-modif-color-dark: (
    "d": 15%,
    "dd": 30%,
);
$list-gardient-direction: (
    "top": 0deg,
    "right": 90deg,
    "bottom": 180deg,
    "left": -90deg,
);

//---------------------------------------
//---------------------------------------
//---------------------------------------

$breakpointName: "";
$hover-list: ("", "hover_");
@each $isHover in $hover-list {
    @each $breakpointName, $breakpoint in $breakpoint-list {
        $point: "screen and (max-width: $breakpoint)";
        @media (max-width: $breakpoint) {
            //----------COLORS--------

            @each $name-color, $color in $colors-list {
                .#{$isHover}bg#{$breakpointName}-#{$name-color} {
                    @if ($isHover != "hover_") {
                        background-color: $color;
                    } @else {
                        &:hover {
                            background-color: $color;
                        }
                    }
                }
                .#{$isHover}text#{$breakpointName}-#{$name-color} {
                    @if ($isHover != "hover_") {
                        color: $color;
                    } @else {
                        &:hover {
                            color: $color;
                        }
                    }
                }
                @each $positionName, $position in ("": "", "t": "-top", "b": "-bottom", "l": "-left", "r": "-right") {
                    @each $size in (0, 1, 2, 3, 4, 5, 10) {
                        .#{$isHover}b#{$positionName}#{$breakpointName}-#{$size}-#{$name-color} {
                            @if ($isHover != "hover_") {
                                border#{$position}: #{$size}px solid #{$color};
                            } @else {
                                &:hover {
                                    border#{$position}: #{$size}px solid #{$color};
                                }
                            }
                        }
                    }
                }

                @each $code-direction, $direction in $list-gardient-direction {
                    .#{$isHover}bg-gradient#{$breakpointName}-#{$code-direction}-#{$name-color} {
                        @if ($isHover != "hover_") {
                            background: linear-gradient($direction, rgba($color, 0) 0%, rgba($color, 1) 100%);
                        } @else {
                            &:hover {
                                background: linear-gradient($direction, rgba($color, 0) 0%, rgba($color, 1) 100%);
                            }
                        }
                    }

                    @each $code, $modif-color in $list-modif-color-light {
                        .#{$isHover}bg#{$breakpointName}-#{$name-color}-#{$code} {
                            @if ($isHover != "hover_") {
                                background-color: lighten($color, $modif-color);
                            } @else {
                                &:hover {
                                    background-color: lighten($color, $modif-color);
                                }
                            }
                        }
                        .#{$isHover}text#{$breakpointName}-#{$name-color}-#{$code} {
                            @if ($isHover != "hover_") {
                                color: lighten($color, $modif-color);
                            } @else {
                                &:hover {
                                    color: lighten($color, $modif-color);
                                }
                            }
                        }
                        .#{$isHover}bg-gradient#{$breakpointName}-#{$code-direction}-#{$name-color}-#{$code} {
                            @if ($isHover != "hover_") {
                                background: linear-gradient(
                                    $direction,
                                    rgba(lighten($color, $modif-color), 0) 0%,
                                    rgba(lighten($color, $modif-color), 1) 100%
                                );
                            } @else {
                                &:hover {
                                    background: linear-gradient(
                                        $direction,
                                        rgba(lighten($color, $modif-color), 0) 0%,
                                        rgba(lighten($color, $modif-color), 1) 100%
                                    );
                                }
                            }
                        }

                        @each $positionName,
                            $position in ("": "", "t": "-top", "b": "-bottom", "l": "-left", "r": "-right")
                        {
                            @each $size in (0, 1, 2, 3, 4, 5, 10) {
                                .#{$isHover}b#{$positionName}#{$breakpointName}-#{$size}-#{$name-color}-#{$code} {
                                    @if ($isHover != "hover_") {
                                        border#{$position}: #{$size}px solid lighten($color, $modif-color);
                                    } @else {
                                        &:hover {
                                            border#{$position}: #{$size}px solid lighten($color, $modif-color);
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @each $code, $modif-color in $list-modif-color-dark {
                        .#{$isHover}bg#{$breakpointName}-#{$name-color}-#{$code} {
                            @if ($isHover != "hover_") {
                                background-color: darken($color, $modif-color);
                            } @else {
                                &:hover {
                                    background-color: darken($color, $modif-color);
                                }
                            }
                        }
                        .#{$isHover}text#{$breakpointName}-#{$name-color}-#{$code} {
                            @if ($isHover != "hover_") {
                                color: darken($color, $modif-color);
                            } @else {
                                &:hover {
                                    color: darken($color, $modif-color);
                                }
                            }
                        }
                        .#{$isHover}bg-gradient#{$breakpointName}-#{$code-direction}-#{$name-color}-#{$code} {
                            @if ($isHover != "hover_") {
                                background: linear-gradient(
                                    $direction,
                                    rgba(darken($color, $modif-color), 0) 0%,
                                    rgba(darken($color, $modif-color), 1) 100%
                                );
                            } @else {
                                &:hover {
                                    background: linear-gradient(
                                        $direction,
                                        rgba(darken($color, $modif-color), 0) 0%,
                                        rgba(darken($color, $modif-color), 1) 100%
                                    );
                                }
                            }
                        }
                        @each $positionName,
                            $position in ("": "", "t": "-top", "b": "-bottom", "l": "-left", "r": "-right")
                        {
                            @each $size in (0, 1, 2, 3, 4, 5, 10) {
                                .#{$isHover}b#{$positionName}#{$breakpointName}-#{$size}-#{$name-color}-#{$code} {
                                    @if ($isHover != "hover_") {
                                        border#{$position}: #{$size}px solid darken($color, $modif-color);
                                    } @else {
                                        &:hover {
                                            border#{$position}: #{$size}px solid darken($color, $modif-color);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //----------TEXT--------

            @each $align in (center, left, right, justify) {
                .#{$isHover}text#{$breakpointName}-#{$align} {
                    @if ($isHover != "hover_") {
                        text-align: $align;
                    } @else {
                        &:hover {
                            text-align: $align;
                        }
                    }
                }
            }

            .#{$isHover}text#{$breakpointName}-italic {
                @if ($isHover != "hover_") {
                    font-style: italic;
                } @else {
                    &:hover {
                        font-style: italic;
                    }
                }
            }

            @each $fontName, $font in $fonts-list {
                .#{$isHover}text#{$breakpointName}-#{$fontName} {
                    @if ($isHover != "hover_") {
                        font-family: $font;
                    } @else {
                        &:hover {
                            font-family: $font;
                        }
                    }
                }
            }

            @each $weightName, $weight in ("bolder": 900, "bold": 500, "normal": 400, "thin": 100) {
                .#{$isHover}text#{$breakpointName}-#{$weightName} {
                    font-weight: $weight;
                    @if ($isHover != "hover_") {
                        font-weight: $weight;
                    } @else {
                        &:hover {
                            font-weight: $weight;
                        }
                    }
                }
            }

            $fonts-size: 10, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 30, 35, 40, 50, 60, 70;
            @each $size in $fonts-size {
                .#{$isHover}text#{$breakpointName}-#{$size} {
                    @if ($isHover != "hover_") {
                        font-size: #{$size}px;
                    } @else {
                        &:hover {
                            font-size: #{$size}px;
                        }
                    }
                }
            }

            @each $size in 1, 2, 3, 4, 5, 10, 13 {
                .#{$isHover}text#{$breakpointName}-spacing-#{$size} {
                    @if ($isHover != "hover_") {
                        letter-spacing: #{$size}px;
                    } @else {
                        &:hover {
                            letter-spacing: #{$size}px;
                        }
                    }
                }
            }

            .#{$isHover}text#{$breakpointName}-uppercase {
                @if ($isHover != "hover_") {
                    text-transform: uppercase;
                } @else {
                    &:hover {
                        text-transform: uppercase;
                    }
                }
            }
            .#{$isHover}text#{$breakpointName}-underline {
                @if ($isHover != "hover_") {
                    text-decoration: underline;
                } @else {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            $size-list: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 40, 50, -1, -2, -3, -4, -5, -6, -7, -8, -9,
                -10, -15, -20, -25, -30, -40, -50;
            $position-list: (
                "top": "t",
                "bottom": "b",
                "left": "l",
                "right": "r",
            );
            $value-list: "", "-";
            $type-list: (
                "m": "margin",
                "p": "padding",
            );

            .m-auto{margin: auto}

            @each $size in $size-list {
                //------INDEX-----
                .#{$isHover}zi#{$breakpointName}-#{$size} {
                    @if ($isHover != "hover_") {
                        z-index: $size;
                    } @else {
                        &:hover {
                            z-index: $size;
                        }
                    }
                }

                @each $type-code, $type in $type-list {
                    @each $value in $value-list {
                        @each $position, $short-code in $position-list {
                            .#{$isHover}#{$value}#{$type-code}#{$short-code}#{$breakpointName}-#{$size} {
                                @if ($isHover != "hover_") {
                                    #{$type}-#{$position}: #{$value}#{$size}em;
                                } @else {
                                    &:hover {
                                        #{$type}-#{$position}: #{$value}#{$size}em;
                                    }
                                }
                            }
                            .#{$isHover}#{$value}#{$type-code}#{$short-code}#{$breakpointName}-0#{$size} {
                                @if ($isHover != "hover_") {
                                    #{$type}-#{$position}: calc(#{$value}#{$size}em / 10);
                                } @else {
                                    &:hover {
                                        #{$type}-#{$position}: calc(#{$value}#{$size}em / 10);
                                    }
                                }
                            }
                        }
                        .#{$isHover}#{$value}#{$type-code}#{$breakpointName}-#{$size} {
                            @if ($isHover != "hover_") {
                                #{$type}: #{$value}#{$size}em;
                            } @else {
                                &:hover {
                                    #{$type}: #{$value}#{$size}em;
                                }
                            }
                        }
                        .#{$isHover}#{$value}#{$type-code}#{$breakpointName}-0#{$size} {
                            @if ($isHover != "hover_") {
                                #{$type}: calc(#{$value}#{$size}em / 10);
                            } @else {
                                &:hover {
                                    #{$type}: calc(#{$value}#{$size}em / 10);
                                }
                            }
                        }

                        .#{$isHover}#{$value}#{$type-code}y#{$breakpointName}-#{$size} {
                            @if ($isHover != "hover_") {
                                #{$type}-top: #{$value}#{$size}em;
                                #{$type}-bottom: #{$value}#{$size}em;
                            } @else {
                                &:hover {
                                    #{$type}-top: #{$value}#{$size}em;
                                    #{$type}-bottom: #{$value}#{$size}em;
                                }
                            }
                        }
                        .#{$isHover}#{$value}#{$type-code}y#{$breakpointName}-0#{$size} {
                            @if ($isHover != "hover_") {
                                #{$type}-top: calc(#{$value}#{$size}em / 10);
                                #{$type}-bottom: calc(#{$value}#{$size}em / 10);
                            } @else {
                                &:hover {
                                    #{$type}-top: calc(#{$value}#{$size}em / 10);
                                    #{$type}-bottom: calc(#{$value}#{$size}em / 10);
                                }
                            }
                        }

                        .#{$isHover}#{$value}#{$type-code}x#{$breakpointName}-#{$size} {
                            @if ($isHover != "hover_") {
                                #{$type}-left: #{$value}#{$size}em;
                                #{$type}-right: #{$value}#{$size}em;
                            } @else {
                                &:hover {
                                    #{$type}-left: #{$value}#{$size}em;
                                    #{$type}-right: #{$value}#{$size}em;
                                }
                            }
                        }
                        .#{$isHover}#{$value}#{$type-code}x#{$breakpointName}-0#{$size} {
                            @if ($isHover != "hover_") {
                                #{$type}-left: calc(#{$value}#{$size}em / 10);
                                #{$type}-right: calc(#{$value}#{$size}em / 10);
                            } @else {
                                &:hover {
                                    #{$type}-left: calc(#{$value}#{$size}em / 10);
                                    #{$type}-right: calc(#{$value}#{$size}em / 10);
                                }
                            }
                        }
                    }
                }
            }

            //----------RADIUS--------
            .#{$isHover}r#{$breakpointName}-none {
                @if ($isHover != "hover_") {
                    border-radius: 0;
                } @else {
                    &:hover {
                        border-radius: 0;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName} {
                @if ($isHover != "hover_") {
                    border-radius: $radiusBasic;
                } @else {
                    &:hover {
                        border-radius: $radiusBasic;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-little {
                @if ($isHover != "hover_") {
                    border-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-radius: $radiusLittle;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-tl-little {
                @if ($isHover != "hover_") {
                    border-top-left-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-top-left-radius: $radiusLittle;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-tr-little {
                @if ($isHover != "hover_") {
                    border-top-right-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-top-right-radius: $radiusLittle;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-bl-little {
                @if ($isHover != "hover_") {
                    border-bottom-left-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-bottom-left-radius: $radiusLittle;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-br-little {
                @if ($isHover != "hover_") {
                    border-bottom-right-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-bottom-right-radius: $radiusLittle;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-full {
                @if ($isHover != "hover_") {
                    border-radius: 50%;
                } @else {
                    &:hover {
                        border-radius: 50%;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-special {
                @if ($isHover != "hover_") {
                    border-radius: $radiusSpecial;
                } @else {
                    &:hover {
                        border-radius: $radiusSpecial;
                    }
                }
            }

            .#{$isHover}r#{$breakpointName}-tl {
                @if ($isHover != "hover_") {
                    border-top-left-radius: $radiusBasic;
                } @else {
                    &:hover {
                        border-top-left-radius: $radiusBasic;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-tr {
                @if ($isHover != "hover_") {
                    border-top-right-radius: $radiusBasic;
                } @else {
                    &:hover {
                        border-top-right-radius: $radiusBasic;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-bl {
                @if ($isHover != "hover_") {
                    border-bottom-left-radius: $radiusBasic;
                } @else {
                    &:hover {
                        border-bottom-left-radius: $radiusBasic;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-br {
                @if ($isHover != "hover_") {
                    border-bottom-right-radius: $radiusBasic;
                } @else {
                    &:hover {
                        border-bottom-right-radius: $radiusBasic;
                    }
                }
            }

            .#{$isHover}r#{$breakpointName}-tl-small {
                @if ($isHover != "hover_") {
                    border-top-left-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-top-left-radius: $radiusLittle;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-tr-small {
                @if ($isHover != "hover_") {
                    border-top-right-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-top-right-radius: $radiusLittle;
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-bl-small {
                @if ($isHover != "hover_") {
                    border-bottom-left-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-bottom-left-radius: $radiusLittle
                    }
                }
            }
            .#{$isHover}r#{$breakpointName}-br-small {
                @if ($isHover != "hover_") {
                    border-bottom-right-radius: $radiusLittle;
                } @else {
                    &:hover {
                        border-bottom-right-radius: $radiusLittle;
                    }
                }
            }

            //----------FLOAT--------
            .#{$isHover}float#{$breakpointName}-l {
                @if ($isHover != "hover_") {
                    float: left;
                } @else {
                    &:hover {
                        float: left;
                    }
                }
            }
            .#{$isHover}float#{$breakpointName}-r {
                @if ($isHover != "hover_") {
                    float: right;
                } @else {
                    &:hover {
                        float: right;
                    }
                }
            }
            .#{$isHover}clear#{$breakpointName} {
                @if ($isHover != "hover_") {
                    clear: both;
                } @else {
                    &:hover {
                        clear: both;
                    }
                }
            }

            //----------DISPLAY--------
            .block#{$breakpointName} {
                display: block;
            }
            .inline#{$breakpointName} {
                display: inline;
            }
            .inline-block#{$breakpointName} {
                display: inline-block;
            }
            .none#{$breakpointName} {
                display: none;
            }
            .flex#{$breakpointName} {
                display: flex;
            }

            //----------POSITION--------
            .relative#{$breakpointName} {
                position: relative;
            }
            .absolute#{$breakpointName} {
                position: absolute;
            }
            .fixed#{$breakpointName} {
                position: fixed;
            }
            .sticky#{$breakpointName} {
                position: sticky;
            }

            //----------HEIGHT--------
            .#{$isHover}h#{$breakpointName}-full {
                @if ($isHover != "hover_") {
                    height: 100%;
                } @else {
                    &:hover {
                        height: 100%;
                    }
                }
            }
            .#{$isHover}min-h#{$breakpointName}-full {
                @if ($isHover != "hover_") {
                    min-height: 100%;
                } @else {
                    &:hover {
                        min-height: 100%;
                    }
                }
            }
            .#{$isHover}max-h#{$breakpointName}-full {
                @if ($isHover != "hover_") {
                    max-height: 100%;
                } @else {
                    &:hover {
                        max-height: 100%;
                    }
                }
            }
            .#{$isHover}h#{$breakpointName}-full-vh {
                @if ($isHover != "hover_") {
                    height: 100vh;
                } @else {
                    &:hover {
                        height: 100vh;
                    }
                }
            }
            .#{$isHover}min-h#{$breakpointName}-full-vh {
                @if ($isHover != "hover_") {
                    min-height: 100vh;
                } @else {
                    &:hover {
                        min-height: 100vh;
                    }
                }
            }
            .#{$isHover}h#{$breakpointName}-mid {
                @if ($isHover != "hover_") {
                    height: 50%;
                } @else {
                    &:hover {
                        height: 50%;
                    }
                }
            }
            .#{$isHover}h#{$breakpointName}-mid-vh {
                @if ($isHover != "hover_") {
                    height: 50vh;
                } @else {
                    &:hover {
                        height: 50vh;
                    }
                }
            }
            .#{$isHover}h#{$breakpointName}-auto {
                @if ($isHover != "hover_") {
                    height: "auto";
                } @else {
                    &:hover {
                        height: "auto";
                    }
                }
            }

            //----------WIDTH--------
            .#{$isHover}w#{$breakpointName}-full {
                @if ($isHover != "hover_") {
                    width: 100%;
                } @else {
                    &:hover {
                        width: 100%;
                    }
                }
            }
            .#{$isHover}min-w#{$breakpointName}-full {
                @if ($isHover != "hover_") {
                    min-width: 100%;
                } @else {
                    &:hover {
                        min-width: 100%;
                    }
                }
            }
            .#{$isHover}max-w#{$breakpointName}-full {
                @if ($isHover != "hover_") {
                    max-width: 100%;
                } @else {
                    &:hover {
                        max-width: 100%;
                    }
                }
            }
            .#{$isHover}w#{$breakpointName}-full-vw {
                @if ($isHover != "hover_") {
                    width: 100vw;
                } @else {
                    &:hover {
                        width: 100vw;
                    }
                }
            }
            .#{$isHover}w#{$breakpointName}-mid-vw {
                @if ($isHover != "hover_") {
                    width: 50vw;
                } @else {
                    &:hover {
                        width: 50vw;
                    }
                }
            }
            .#{$isHover}w#{$breakpointName}-auto {
                @if ($isHover != "hover_") {
                    width: "auto";
                } @else {
                    &:hover {
                        width: "auto";
                    }
                }
            }

            $column-list: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
            @each $column in $column-list {
                @each $size-column in $column-list {
                    $size: $size-column - 1;
                    .#{$isHover}w#{$breakpointName}-#{$size}\/#{$column} {
                        width: calc((100% / #{$column}) * #{$size});
                        @if ($isHover != "hover_") {
                            width: calc((100% / #{$column}) * #{$size});
                        } @else {
                            &:hover {
                                width: calc((100% / #{$column}) * #{$size});
                            }
                        }
                    }
                }
            }

            //----------FLEX--------

            @each $size in (1, 2, 3) {
                .#{$isHover}f#{$breakpointName}-#{$size} {
                    @if ($isHover != "hover_") {
                        flex: $size;
                    } @else {
                        &:hover {
                            flex: $size;
                        }
                    }
                }
            }

            .f#{$breakpointName}-column {flex-direction: column}
            .f#{$breakpointName}-column-reverse {flex-direction: column-reverse}
            .f#{$breakpointName}-row {flex-direction: row}

            .f#{$breakpointName}-wrap {flex-wrap: wrap;}
            .f#{$breakpointName}-nowrap {flex-wrap: nowrap;}

            .f#{$breakpointName}-left {justify-content: flex-start}
            .f#{$breakpointName}-top {align-items: flex-start}
            .f#{$breakpointName}-right {justify-content: flex-end}
            .f#{$breakpointName}-bottom {align-items: flex-end}

            .f#{$breakpointName}-center-w {justify-content: center}
            .f#{$breakpointName}-center-h {align-items: center}
            .f#{$breakpointName}-center {
              align-items: center;
              justify-content: center
            }

            .f#{$breakpointName}-full-w {justify-content: space-between}
            .f#{$breakpointName}-full-h {align-items: stretch}
            .f#{$breakpointName}-full{
              justify-content: space-between;
              align-items: stretch
            }

            .item#{$breakpointName}-stretch {align-self: stretch;}

            //----------OPACITY--------
            $opacity-list: (
                "1": 1,
                "75": 0.75,
                "50": 0.5,
                "25": 0.25,
                "0": 0,
            );

            @each $opacity-code, $opacity-value in $opacity-list {
                .#{$isHover}opacity#{$breakpointName}-#{$opacity-code} {
                    @if ($isHover != "hover_") {
                        opacity: $opacity-value;
                    } @else {
                        &:hover {
                            opacity: $opacity-value;
                        }
                    }
                }
            }

            //-------BEFORE / AFTER-----

            .no#{$breakpointName}-before:before{display: none !important;}
            .no#{$breakpointName}-after:after{display: none !important;}

            //----------CURSOR--------
            .pointer#{$breakpointName} {
                cursor: pointer;
            }
            .cursor#{$breakpointName}-default {
                cursor: default;
            }

            //----------OVERFLOW--------
            .#{$isHover}overflow#{$breakpointName} {
                @if ($isHover != "hover_") {
                    overflow: auto;
                } @else {
                    &:hover {
                        overflow: auto;
                    }
                }
            }
            .#{$isHover}overflow#{$breakpointName}-h {
                @if ($isHover != "hover_") {
                    overflow: hidden;
                } @else {
                    &:hover {
                        overflow: hidden;
                    }
                }
            }

            .#{$isHover}mode#{$breakpointName}-difference {
                @if ($isHover != "hover_") {
                    mix-blend-mode: difference;
                } @else {
                    &:hover {
                        mix-blend-mode: difference;
                    }
                }
            }
            .#{$isHover}mode#{$breakpointName}-color-dodge {
                @if ($isHover != "hover_") {
                    mix-blend-mode: color-dodge;
                } @else {
                    &:hover {
                        mix-blend-mode: color-dodge;
                    }
                }
            }
        }
    }
}

//--------TRANSITION---------
.transition {
    @include transition(500ms);
}

.w-xl-content {
    width: $widthSizeLargeScreen;
    margin: auto;
    @media (max-width: $pointSmallDesktop) {
        width: 100%;
    }
}

.hyphens {
    hyphens: auto;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
.flex-wrap {
    flex-flow: row wrap;
}
.w-100 {
    flex: 0 0 100%;
    max-width: 100%;
}
.link-a {
    a {
        text-decoration: underline;
        hyphens: auto;
    }
}
