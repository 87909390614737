.st0 {
  //fill: #e54d4f;
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
.st1 {
  fill: #e54d4f;
}
.st2 {
  fill: #1d1d1b;
}
.st3 {
  fill: #f3d1dd;
}
.st4 {
  clip-path: url(#SVGID_2_);
}
.st5 {
  //fill: #d866a3;
}
.st6{
  clip-path:url(#maskTest_1_);
}
.st7 {
  fill: url(#Fond_1_);
  stroke: #1d1d1b;
  stroke-miterlimit: 10;
}
.st8 {
  //fill: #e79ac3;
  stroke: #1d1d1b;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st9 {
  //fill: #d866a3;
  stroke: #1d1d1b;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
}
.st10 {
  fill: none;
  stroke: #1d1d1b;
  stroke-width: 0.75;
  stroke-miterlimit: 10;
}

.cls-1 {
  //fill: #1d1d1b;
  //stroke: #1d1d1b;
  stroke-width: 0.2;
}

.st11{opacity:0.5;fill:#B54735;enable-background:new    ;}
	.st12{fill:#99CCE0;stroke:#1D1D1B;stroke-miterlimit:10;}
	.st13{fill:#99CCE0;}
	.st14{fill:url(#SVGID_5_);}
	.st15{fill:#99DDAD;stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st16{fill:url(#SVGID_6_);stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st17{fill:url(#SVGID_7_);stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st18{fill:url(#SVGID_8_);stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st19{fill:url(#SVGID_9_);stroke:#1D1D1B;stroke-width:0.25;stroke-miterlimit:10;}
	.st20{fill:url(#SVGID_10_);stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st21{fill:url(#SVGID_11_);stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st22{clip-path:url(#SVGID_13_);}
	.st23{clip-path:url(#SVGID_15_);}
	.st24{clip-path:url(#SVGID_17_);}
	.st25{fill:url(#Fond_2_);stroke:#1D1D1B;stroke-miterlimit:10;}
	.st26{display:none;fill:#E79AC3;stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st27{clip-path:url(#SVGID_19_);fill:none;stroke:#1D1D1B;stroke-width:0.75;stroke-miterlimit:10;}
	.st28{clip-path:url(#SVGID_21_);}
	.st29{fill:#99CCE0;stroke:#1D1D1B;stroke-width:1.2196;stroke-miterlimit:10;}
	.st30{fill:#C4C4C4;stroke:#1D1D1B;stroke-miterlimit:10;}
	.st31{fill:none;stroke:#1D1D1B;stroke-miterlimit:10;}
	.st32{fill:#C4C4C4;}
	.st33{fill:none;stroke:#1D1D1B;stroke-width:0.25;stroke-miterlimit:10;}
	.st34{fill:#AA0C49;}
	.st35{fill:none;stroke:#1D1D1B;stroke-width:0.25;stroke-linecap:round;stroke-miterlimit:10;}
	.st36{fill:url(#SVGID_22_);}
	.st37{fill:#AA0C49;stroke:#1D1D1B;stroke-width:0.5;stroke-miterlimit:10;}
	.st38{fill:#C4C4C4;stroke:#1D1D1B;stroke-width:1.2424;stroke-miterlimit:10;}
	.st39{fill:#E2E2E2;}
	.st40{clip-path:url(#SVGID_24_);}
	.st41{fill:url(#SVGID_25_);}
	.st42{fill:url(#SVGID_26_);}
	.st43{clip-path:url(#SVGID_28_);}
	.st44{fill:url(#SVGID_29_);}
	.st45{fill:url(#SVGID_30_);}
	.st46{clip-path:url(#SVGID_32_);}
	.st47{clip-path:url(#SVGID_34_);fill:#E54D4F;}
	.st48{clip-path:url(#SVGID_34_);fill:#1D1D1B;}
	.st49{clip-path:url(#SVGID_34_);fill:#D866A3;}
	.st50{clip-path:url(#SVGID_36_);fill:url(#SVGID_37_);}
	.st51{filter:url(#Adobe_OpacityMaskFilter);}
	.st52{clip-path:url(#SVGID_39_);}
	.st53{enable-background:new    ;}
	.st54{clip-path:url(#SVGID_42_);fill:url(#SVGID_43_);}
	.st55{clip-path:url(#SVGID_39_);mask:url(#SVGID_40_);}
	.st56{clip-path:url(#SVGID_45_);}
	.st57{filter:url(#Adobe_OpacityMaskFilter_1_);}
	.st58{clip-path:url(#SVGID_47_);fill:url(#SVGID_48_);}
	.st59{clip-path:url(#SVGID_47_);fill:url(#SVGID_49_);}
	.st60{clip-path:url(#SVGID_51_);fill:#99CCE0;}
	.st61{clip-path:url(#SVGID_51_);fill:none;stroke:#1D1D1B;stroke-miterlimit:10;}
	.st62{clip-path:url(#SVGID_51_);fill:#1D1D1B;}
	.st63{clip-path:url(#SVGID_51_);fill:none;stroke:#1D1D1B;stroke-width:1.22;stroke-miterlimit:10;}
	.st64{clip-path:url(#SVGID_51_);fill:#C4C4C4;}
	.st65{clip-path:url(#SVGID_51_);fill:none;stroke:#1D1D1B;stroke-width:0.25;stroke-miterlimit:10;}
	.st66{clip-path:url(#SVGID_53_);}
	.st67{clip-path:url(#SVGID_55_);fill:#E54D4F;}
	.st68{clip-path:url(#SVGID_55_);fill:#1D1D1B;}
	.st69{clip-path:url(#SVGID_55_);fill:#D866A3;}
	.st70{clip-path:url(#SVGID_57_);fill:url(#SVGID_58_);}
	.st71{filter:url(#Adobe_OpacityMaskFilter_2_);}
	.st72{clip-path:url(#SVGID_60_);}
	.st73{clip-path:url(#SVGID_63_);fill:url(#SVGID_64_);}
	.st74{clip-path:url(#SVGID_60_);mask:url(#SVGID_61_);}
	.st75{clip-path:url(#SVGID_66_);}
	.st76{filter:url(#Adobe_OpacityMaskFilter_3_);}
	.st77{clip-path:url(#SVGID_68_);fill:url(#SVGID_69_);}
	.st78{clip-path:url(#SVGID_68_);fill:url(#SVGID_70_);}
	.st79{clip-path:url(#SVGID_72_);fill:#99CCE0;}
	.st80{clip-path:url(#SVGID_72_);fill:none;stroke:#1D1D1B;stroke-miterlimit:10;}
	.st81{clip-path:url(#SVGID_72_);fill:#1D1D1B;}
	.st82{clip-path:url(#SVGID_74_);fill:url(#SVGID_75_);}
	.st83{clip-path:url(#SVGID_77_);fill:#1D1D1B;}
	.st84{clip-path:url(#SVGID_77_);fill:#99CCE0;}
