@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans|Kaushan+Script|Lato:100,100i,300,300i,400,400i,500,900');

//-------------COLORS------------

$neutral: #bdbdc7;
$neutral-l: #f0f0f7;
$primary: #BF9676;
$secondary: #0D31C6;
$tertiary: #0D31C6;


$colors-list:(
    'primary':$primary,
    'secondary':$secondary,
    'tertiary': $tertiary,

    'white': #ffffff,
    'black': #000000, 
    'none': transparent,
    'neutral': $neutral,
    'neutral-l': $neutral-l
    );

//-------------FONTS------------
$fontMain : 'Lato', sans-serif;
$fontTitle: 'Alegreya Sans', sans-serif;
$fontSpecial: 'Kaushan Script', cursive;

$fonts-list:('main':$fontMain,'title':$fontTitle,'special':$fontSpecial);

//-------------BREAK POINT------------
$widthSizeLargeScreen : 1000px;

$pointSmallDesktop: 1100px;
$pointTabletPortrait: 965px;
$pointPhonePortrait: 700px;

$breakpoint-list : ('': 10000px, '-xl': 10000px, '-lg': $pointSmallDesktop, '-md': $pointTabletPortrait, '-sm': $pointPhonePortrait);

//-------------RADIUS------------
$radiusLittle: 15px;
$radiusBasic : 20px;
$radiusFull: 50%;
$radiusSpecial : 49% 51% 25% 75% / 52% 72% 28% 48%;

@mixin transition($duration) {
    -webkit-transition: $duration;
       -moz-transition: $duration;
        -ms-transition: $duration;
            transition: $duration;
}


