.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  transition: .2s all;
  -webkit-tap-highlight-color: transparent;


  .burger-lines:after {
    left: 0;
    top: -1em;
  }
  .burger-lines:before {
    left: 0em;
    top: 1em;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    height: 150%;
    width: 150%;
    top: -25%;
    left: -25%;
  }
  .burger-lines {
    top: 50%;
    margin-top: -0.125em;
    &,
    &:after,
    &:before {
      pointer-events: none;
      display: block;
      content: '';
      width: 100%;
      border-radius: 0.25em;
      background-color: white;
      height: 0.25em;
      position: absolute;
      transform: rotate(0);
    }
    &:after {
      left: 0;
      top: -1em;
    }
    &:before {
      left: 0em;
      top: 1em;
    }
  }
}


.burger.burger-squeeze {
  .burger-lines {
    &,
    &:after,
    &:before {
      transition: .2s top .2s, .1s left, .2s transform, .4s background-color .2s;
    }
    &:after,
    &:before {
      width: 100%;
    }
  }
  
  &.open {
    .burger-lines {
      &,
      &:after,
      &:before {
        transition: .2s background-color, .2s top, .2s left, .2s transform .15s;
      }
      & {
        background-color: transparent;
      }
      &:before,
      &:after {
        left: 0em;
        top: 0px;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
    }
  }
}